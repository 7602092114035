import { useState, useEffect } from "react";
import JsonData from "./data/data.json";


import { Navigation } from './components/navigation';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import { Copyright } from "./components/copyright";

import { Footer } from "./components/footer";
import Home from "./Home";
import { PrivacyPolicies } from './components/pages/privacypolicy'

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="App">

      <div className="social">

        <div className="pop">
          <a className="phonescreen" href="tel:18005712225 ">
            <img style={{ border: "0" }} src="img/banners/quickenquiry2.png" alt="callus@18005712225 " />
          </a>
        </div>

      </div>

      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicies />} />

      </Routes>
      <Footer />
      <Copyright />
    </div>
  );
}

export default App;
